import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "observations";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllObservations = (studentUUID) => {
    return PedagogicalDiaryService.get(`${RESOURCE}/student/${studentUUID}`);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllObservationsInTimeRange = (studentUUID, from, to) => {
    return PedagogicalDiaryService.get(`${RESOURCE}/student/${studentUUID}/from-date/${from}/to-date/${to}`);
};

export const createObservation = (payload) => {
    return PedagogicalDiaryService.post(`${RESOURCE}`, payload);
};

export const updateObservation = (id, payload) => {
    return PedagogicalDiaryService.put(`${RESOURCE}`, id, payload);
};

export const deleteObservation = (id) => {
    return PedagogicalDiaryService.delete(`${RESOURCE}/${id}`);
};

export default {
    getAllObservations,
    getAllObservationsInTimeRange,
    createObservation,
    updateObservation,
    deleteObservation,
};
