<template>
  <div>
    <div class="white rounded-lg pa-5 py-md-8 px-md-10">
      <template>
        <!-- begin::Page header and title -->
        <div class="card-title">
          <h3 class="card-label">
            Asignación de observaciones
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui podrá registrar una observación a un estudiante.</span
            >
          </h3>
        </div>
        <!-- end::Page header and title -->

        <!-- begin::autocomplete select for student -->
        <v-row>
          <v-col>
            <v-autocomplete
              :label="autocompleteLabel"
              :loading="areStudentsLoading"
              :disabled="
                areStudentsLoading || !students.length || !onlyOneStudent
              "
              filled
              rounded
              hide-details
              clearable
              :items="students"
              item-text="code"
              item-value="uuid"
              v-model="report.params.code"
              @change="get(report.params.code)"
              :filter="customFilter"
            >
              <!-- begin::selected student item -->
              <template v-slot:selection="{ item, index }">
                <v-chip color="primary" small v-if="index === 0">
                  <span>{{ item.full_name }}</span>
                </v-chip>
              </template>
              <!-- end::selected student item -->
              <template v-slot:item="{ item, index }">
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-medium">
                      {{ item.code }}
                    </span>
                    -
                    <span class="text-uppercase font-weight-medium">
                      {{ item.full_name }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="mt-1">
                    <span>
                      Grado
                      <strong class="ml-1">{{ item.grade }}</strong>
                    </span>
                    <span class="ml-2">
                      Sección
                      <strong class="ml-1">{{ item.section_group }}</strong>
                    </span>
                    <span class="ml-2">
                      Especialidad
                      <strong class="ml-1">{{ item.technical_group }}</strong>
                    </span>
                  </v-list-item-subtitle>
                  <v-divider
                    class=""
                    v-if="index < filteredStudent.length - 1"
                  ></v-divider>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <!-- end::autocomplete select for student -->

        <!-- begin:: Academic and technical score summary container  -->
        <v-container v-if="studentHasBeenSelected">
          <v-card class="elevation-0 pa-3 rounded-lg mt-5" outlined>
            <p class="text-h6 font-weight-bold">Redactar observación:</p>
            <form-group name="Motivo" :validator="$v.motive">
              <v-textarea
                slot-scope="{ attrs }"
                v-bind="attrs"
                counter
                filled
                clearable
                rounded
                auto-grow
                rows="2"
                label="Motivo"
                v-model.trim="motive"
                @paste="handlePaste($event, 'motive')"
                @keyup.enter="handleKeyUpMotive()"
              ></v-textarea>
            </form-group>

            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  elevation="0"
                  :loading="isSavingObservation"
                  :disabled="isSavingObservation"
                  @click="registerObservation()"
                  >Añadir observación a expediente</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <!-- begin::falback screen when a student hasn't been selected -->
        <v-container v-else>
          <v-row class="mt-10">
            <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
              <div class="mx-auto mb-4 pa-8 rounded-circle red lighten-4">
                <v-icon large color="red">mdi-account-group-outline</v-icon>
              </div>
              <div>
                <p class="text-center font-weight-bold text-h6 mb-1">
                  Seleccione un estudiante o ingrese un código de carnet
                </p>
                <p class="text-center font-weight-medium text-body-1">
                  Al seleccionar un estudiante o ingresar un código de carnet,
                  podrá añadirle una observación al expediente.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <!-- end::falback screen when a student hasn't been selected -->
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import studentRepository from "@/repositories/studentRepository";
import observationRepository from "@/repositories/observationRepository";
import {
  required,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

const alpha = helpers.regex("alpha", /^[\(\)a-zA-Z\d-_.,:;ñÑáéíóúÁÉÍÓÚ\s]+$/i);

export default {
  name: "CreateObservation",
  title: "Asignar observación | GE ITR",

  mounted() {
    this.verifyIfTeacherGuide();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Diario Pedagógico" },
      {
        title: "Observaciones",
        route: "create_observation",
      },
    ]);
  },
  data() {
    return {
      motive: "",
      studentHasBeenSelected: false,
      autocompleteLabel: "Código ó nombre del estudiante",
      filteredStudent: [],
      onlyOneStudent: true,
      students: [],
      areStudentsLoading: false,
      isSavingObservation: false,
      teacherGuideStudents: [],
      report: {
        params: {},
      },
    };
  },
  methods: {
    /*If there's a code available when an "onChange" event takes
       place, the space to create the observation will be shown. otherwise, dont.*/
    get(code) {
      this.$v.$reset();
      if (code !== null) {
        this.studentHasBeenSelected = true;
        return;
      }
      this.studentHasBeenSelected = false;
    },

    //Custom filter to search by code and full name
    customFilter(item, queryText, itemText) {
      const searchText = queryText.toLowerCase();
      const codeMatch = item.code.toString().includes(searchText);
      const fullNameMatch = item.full_name.toLowerCase().includes(searchText);
      return codeMatch || fullNameMatch;
    },
    // Function that verifies that if you have a degree assigned as a guide teacher
    verifyIfTeacherGuide() {
      this.areStudentsLoading = true;
      studentRepository
        .verifyIfTeacherGuide(this.currentUserPersonalInfo.id_user)
        .then(({ data }) => {
/*           if (
            this.getUserType === "Docente académico" ||
            this.getUserType === "Docente técnico"
          ) { */
            if (false) {
            //if (data.exist) {
            if (data.exist) {
              // If you are a guide teacher, we go through the groups (in case you are a guide teacher of more than one grade) and send for the students.
              this.teacherGuideStudents = data.data;
              this.teacherGuideStudents.forEach((element) => {
                // If the group information contains the section_group_id, it means that it is an academic students
                if (!!element.groupable.section_group_id) {
                  this.areStudentsLoading = true;
                  studentRepository
                    .getAcademicStudents(element.groupable.id)
                    .then(({ data }) => {
                      this.students.push(...data);
                    })
                    .catch((error) => {
                      throw new Error(error);
                    })
                    .finally(() => {
                      this.areStudentsLoading = false;
                    });
                } else {
                  //If this goes in else bring the technical students
                  this.areStudentsLoading = true;
                  studentRepository
                    .getTechnicalStudent(element.groupable.id)
                    .then(({ data }) => {
                      this.students.push(...data);
                    })
                    .catch((error) => {
                      throw new Error(error);
                    })
                    .finally(() => {
                      this.areStudentsLoading = false;
                    });
                }
              });
            } else {
              this.autocompleteLabel = "No tiene grupos asignados";
              this.areStudentsLoading = false;
            }
          } else {
            this.getStudents();
          }
        })
        .catch(() => {
          this.areStudentsLoading = false;
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    //fetch all students
    getStudents() {
      this.areStudentsLoading = true;
      studentRepository
        .getAllWithAcademicInformation()
        .then(({ data }) => {
          this.students = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.areStudentsLoading = false;
        });
    },

    handlePaste(event) {
      event.preventDefault();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      paste = paste.replace(/ {3,}/g, "  ");
      this.motive = this.motive + paste;
    },

    handleKeyUpMotive() {
      this.$v.motive.$touch();
      if (!this.$v.motive.$invalid) {
        this.verticalFormStep = 3;
      }
    },

    registerObservation() {
      this.$v.motive.$touch();
      if (!this.$v.motive.$invalid) {
        this.isSavingObservation = true;

        let payload = {
          observation: this.motive,
          student_id: this.report.params?.code,
          user_id: this.currentUserPersonalInfo.id_user,
          date: new Date().toISOString().slice(0, 10),
        };

        observationRepository
          .createObservation(payload)
          .then(() => {
            this.sweetAlertResponse({
              status: true,
              message: `La observación se ha registrado con éxito.`,
            });

            this.resetCardData();
          })
          .catch((error) => {
            this.sweetAlertResponse({
              status: false,
              message: `No se pudo regitrar la observación. Por favor, intente más tarde o contacte a  soporte técnico.`,
            });
            console.log(error);
          })
          .finally(() => {
            this.isSavingObservation = false;
          });

        return;
      }
    },

    resetCardData() {
      this.motive = "";
      this.studentHasBeenSelected = null;
      this.report.params.code = null;
      this.$v.$reset();
    },
  },

  watch: {
    motive(newVal) {
      if (!!this.motive) {
        this.motive = newVal.replace(/ {3,}/g, " ");
      }
    },
  },

  computed: {
    today() {
      let date = new Date();
      //includes time
      return date.toISOString();
    },
    ...mapGetters(["userType", "currentUserPersonalInfo"]),
    getUserType() {
      const { user_type: userType } = this.userType;
      return userType;
    },
    getApiBaseURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.GE_STUDENTS_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.GE_STUDENTS_DEV_API_URL;
      } else {
        baseURL = process.env.GE_STUDENTS_LOCAL_API_URL;
      }
      return baseURL;
    },
  },

  validations: {
    motive: {
      required,
      minLength: minLength(5),
     //alpha,
    },
  },
};
</script>